.machine-specific-gallery__container {
	display: grid;
	width: 100%;
	overflow: hidden;
	overflow: auto;
	background: transparent;
	padding: 1rem;
}
.machine-specific-gallery {
	display: grid;
	width: 100%;
	overflow: hidden;
	overflow: auto;
	&__view-all-button {
		display: grid;
		justify-self: center;
		margin-top: 2rem;
		background: $primary-color;
		border: none;
		color: white;
		padding: 1rem 2rem;
		border-radius: 4px;
		cursor: pointer;
		transition: all 0.3s ease;
		&:hover {
			background: darken($primary-color, 10%);
		}
	}
	&__grid {
		display: flex;
		position: relative;
	}
	&__image-wrapper {
		flex: 0 0 auto;
		width: 90%;
		max-width: 200px;
		margin-right: 1rem;
		cursor: pointer;
		transition: all 0.3s ease;
		&:hover {
			opacity: 0.8;
		}
	}
	&__image {
		width: 100%;
		height: auto;
		object-fit: cover;
		border-radius: 4px;
	}
	&__view-all-modal {
		display: grid;
		margin: auto;
		max-width: 2800px;
		position: fixed;
		align-items: flex-start;
		top: 3rem;
		inset: 0;
		background: rgba(0, 0, 0, 0.8);
		z-index: 100;
		overflow: auto;
		&__grid {
			display: grid;
			position: relative;
			justify-content: space-between;
			padding: 0.5rem;
			gap: 0.5rem;
			grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
			&__close {
				position: fixed;
				top: 1rem;
				right: 1rem;
				background: rgba(0, 0, 0, 0.6);
				color: #fff;
				border: none;
				border-radius: 50%;
				width: 2.5rem;
				height: 2.5rem;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 1.5rem;
				cursor: pointer;
				z-index: 121;
			}
			&__image-wrapper {
				cursor: pointer;
				&__image {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	&__view-individual-image-modal {
		display: grid;
		position: fixed;
		align-items: center;
		align-content: center;
		inset: 0;
		background: rgba(0, 0, 0, 0.8);
		z-index: 120;
		overflow: auto;
		&__content {
			display: grid;
			position: relative;
			padding: 0.25rem;
			&__image-wrapper {
				display: grid;
				position: relative;
				height: max-content;
				&__arrow {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					color: #fff;
					border: none;
					background: rgb(46, 46, 46);
					width: 2rem;
					height: 2rem;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					z-index: 121;
					&.left {
						left: 0.25rem;
					}
					&.right {
						right: 0.25rem;
					}
				}
				&__close {
					position: absolute;
					background: rgba(0, 0, 0, 0.6);
					color: #fff;
					border: none;
					border-radius: 50%;
					width: 1.5rem;
					height: 1.5rem;
					right: 0.5rem;
					top: 0.5rem;
					display: flex;
					align-items: center;
					align-content: center;
					justify-content: center;
					font-size: 1.5rem;
					cursor: pointer;
					z-index: 121;
				}
				&__image {
					width: 100%;
					height: 100%;
					object-fit: contain;
					max-height: 95vh;
				}
			}
		}
	}
}

@include responsive(small) {
	.machine-specific-gallery {
		&__grid {
			display: flex;
			justify-content: space-between;
		}
		&__image-wrapper {
			max-width: 200px;
		}
		&__view-all-modal {
			&__grid {
				display: grid;
				padding: 0.5rem;
				gap: 0.5rem;
				grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
				&__image-wrapper {
					&__image {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
		}
		&__view-individual-image-modal {
			display: grid;
			position: fixed;
			align-items: center;
			align-content: center;
			inset: 0;
			background: rgba(0, 0, 0, 0.8);
			z-index: 120;
			overflow: auto;
			&__content {
				display: grid;
				justify-content: center;
				position: relative;
				padding: 0.25rem;
				width: 100%;
				&__image-wrapper {
					display: grid;
					position: relative;
					height: max-content;
					width: max-content;
					&__arrow {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						color: #fff;
						border: none;
						background: rgb(46, 46, 46);
						width: 2rem;
						height: 2rem;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						z-index: 121;
						&.left {
							left: 0.25rem;
						}
						&.right {
							right: 0.25rem;
						}
					}
					&__close {
						position: absolute;
						background: rgba(0, 0, 0, 0.6);
						color: #fff;
						border: none;
						border-radius: 50%;
						width: 1.5rem;
						height: 1.5rem;
						right: 0.5rem;
						top: 0.5rem;
						display: flex;
						align-items: center;
						align-content: center;
						justify-content: center;
						font-size: 1.5rem;
						cursor: pointer;
						z-index: 121;
					}
					&__image {
						width: 100%;
						object-fit: contain;
						height: 95vh;
					}
				}
			}
		}
	}
}

@include responsive(medium) {
	.machine-specific-gallery {
		&__grid {
			display: flex;
			justify-content: space-between;
		}
		&__image-wrapper {
		}
		&__view-all-modal {
			&__grid {
				grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
				&__image-wrapper {
					display: grid;
					width: 250px;
					height: 100%;
					&__image {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
		}
		&__view-individual-image-modal {
			display: grid;
			position: fixed;
			align-items: center;
			align-content: center;
			inset: 0;
			background: rgba(0, 0, 0, 0.8);
			z-index: 120;
			overflow: auto;
			&__content {
				display: grid;
				justify-content: center;
				position: relative;
				padding: 0.25rem;
				width: 100%;
				&__image-wrapper {
					display: grid;
					position: relative;
					height: max-content;
					width: max-content;
					&__arrow {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						color: #fff;
						border: none;
						background: rgb(46, 46, 46);
						width: 2rem;
						height: 2rem;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						z-index: 121;
						&.left {
							left: 0.25rem;
						}
						&.right {
							right: 0.25rem;
						}
					}
					&__close {
						position: absolute;
						background: rgba(0, 0, 0, 0.6);
						color: #fff;
						border: none;
						border-radius: 50%;
						width: 1.5rem;
						height: 1.5rem;
						right: 0.5rem;
						top: 0.5rem;
						display: flex;
						align-items: center;
						align-content: center;
						justify-content: center;
						font-size: 1.5rem;
						cursor: pointer;
						z-index: 121;
					}
					&__image {
						width: 100%;
						object-fit: contain;
						height: 95vh;
					}
				}
			}
		}
	}
}

@include responsive(large) {
	.machine-specific-gallery {
		&__grid {
			display: flex;
			justify-content: space-between;
		}
		&__image-wrapper {
			max-width: 300px;
		}
		&__view-all-modal {
			&__grid {
				grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
				&__image-wrapper {
					display: grid;
					width: 350px;
					height: auto;
					&__image {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
		}
		&__view-individual-image-modal {
			display: grid;
			position: fixed;
			align-items: center;
			align-content: center;
			inset: 0;
			background: rgba(0, 0, 0, 0.8);
			z-index: 120;
			overflow: auto;
			&__content {
				display: grid;
				justify-content: center;
				position: relative;
				padding: 0.25rem;
				width: 100%;
				&__image-wrapper {
					display: grid;
					position: relative;
					height: max-content;
					width: max-content;
					&__arrow {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						color: #fff;
						border: none;
						background: rgb(46, 46, 46);
						width: 2rem;
						height: 2rem;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						z-index: 121;
						&.left {
							left: 0.25rem;
						}
						&.right {
							right: 0.25rem;
						}
					}
					&__close {
						position: absolute;
						background: rgba(0, 0, 0, 0.6);
						color: #fff;
						border: none;
						border-radius: 50%;
						width: 1.5rem;
						height: 1.5rem;
						right: 0.5rem;
						top: 0.5rem;
						display: flex;
						align-items: center;
						align-content: center;
						justify-content: center;
						font-size: 1.5rem;
						cursor: pointer;
						z-index: 121;
					}
					&__image {
						width: 100%;
						object-fit: contain;
						height: 95vh;
					}
				}
			}
		}
	}
}

@include responsive(xlarge) {
	.machine-specific-gallery {
		&__grid {
			display: flex;
			justify-content: space-between;
		}
		&__image-wrapper {
			max-width: 400px;
		}
		&__view-all-modal {
			&__grid {
				grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
				&__image-wrapper {
					display: grid;
					width: 300px;
					height: auto;
					&__image {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
		}
		&__view-individual-image-modal {
			display: grid;
			position: fixed;
			align-items: center;
			align-content: center;
			inset: 0;
			background: rgba(0, 0, 0, 0.8);
			z-index: 120;
			overflow: auto;
			&__content {
				display: grid;
				justify-content: center;
				position: relative;
				padding: 0.25rem;
				width: 100%;
				&__image-wrapper {
					display: grid;
					position: relative;
					height: max-content;
					width: max-content;
					&__arrow {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						color: #fff;
						border: none;
						background: rgb(46, 46, 46);
						width: 2rem;
						height: 2rem;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						z-index: 121;
						&.left {
							left: 0.25rem;
						}
						&.right {
							right: 0.25rem;
						}
					}
					&__close {
						position: absolute;
						background: rgba(0, 0, 0, 0.6);
						color: #fff;
						border: none;
						border-radius: 50%;
						width: 1.5rem;
						height: 1.5rem;
						right: 0.5rem;
						top: 0.5rem;
						display: flex;
						align-items: center;
						align-content: center;
						justify-content: center;
						font-size: 1.5rem;
						cursor: pointer;
						z-index: 121;
					}
					&__image {
						width: 100%;
						object-fit: contain;
						height: 95vh;
					}
				}
			}
		}
	}
}
