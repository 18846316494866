$paragraph-color: #111111;
$paragraph1-color: #222222;
$paragraph2-color: #444444;
$paragraph3-color: #666666;
$paragraph4-color: #999999;
$paragraph5-color: #cccccc;
$paragraph6-color: #ffffff;
$highlight-color: #2565a8;
$breakpoints: (
	"small": 768px,
	"medium": 1200px,
	"large": 1800px,
	"xlarge": 2400px,
);

$factorycatred: #ed2024;
$factorycatreddarken: #c01f22;
$mobile-width-padding: 0rem 0.5rem;
$notepad-width-padding: 0rem 2rem;
$desktop-width-padding: 0rem 4rem;
$mobile-padding: 0rem 2%;
$tablet-padding: 0rem 4%;
$desktop-padding: 0rem 7rem;

$mobile-section-padding: 3rem 2%;
$tablet-section-padding: 4rem 4%;
$desktop-section-padding: 5rem 7%;

$font-stack: Helvetica, sans-serif;
$alt-color: #111;
$primary-grey: #111;
$primary-color: rgb(29, 76, 160);
$highlight-color: rgb(71, 116, 196);
$font-stack: Helvetica, sans-serif;
$alt-color: #111;
$primary-grey: #111;
$primary-color: #ed2024;
$large-nav-width: 1800px;
$medium-nav-width: 1200px;
$factorycatred: #ed2024;

@mixin responsive($breakpoint) {
	$value: map-get($breakpoints, $breakpoint);
	@if $value !=null {
		// Prints a media query based on the value
		@media (min-width: $value) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
	}
}
