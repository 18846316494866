@import url("https://use.typekit.net/izn0adj.css");
@import "./buttons.scss";
@import "./variables.scss";
@import "./forms.scss";
@import "./requestDemo.scss";
@import "./mobilenavigation.scss";
@import "./options.scss";
@import "./why-fc-page.scss";
@import "./individual-machine-gallery.scss";
@import "./platforms.scss";
@import "./machinepage.scss";
@import "./sizechanger.scss";
@import "./controllers.scss";
@import "./handcrafted.scss";
@import "./highlightsection.scss";
@import "./machinechasis.scss";
@import "./machineoverview.scss";
@import "./sectionone.scss";
@import "./header.scss";
@import "./desktopdropdownmenu.scss";
@import "./desktopnavigation.scss";
@import "./about.scss";
@import "./applications.scss";
@import "./footer.scss";
@import "./registration.scss";
@import "./home-gallery.scss";
@import "./cardsection.scss";
@import "./factorycatstory.scss";
@import "./headerbanner2.scss";
@import "./scrubbertypes.scss";
@import "./gallery.scss";
@import "./contact.scss";
@import "./downloads.scss";
@import "./serial-form.scss";
@import "./video.scss";
@import "./alerts.scss";
@import "./support.scss";
@import "./partsPage.scss";
@import "./parts-explorer.scss";

@import "./blog-post-page.scss";
@import "./blog.scss";
@import "./sections.scss";
@import "./all-parts-viewer.scss";
@import "./request-a-quote-btn.scss";
@import "./faq.scss";
@import "./new-machine-page.scss";
@import "./devPage.scss";
@import "./home-two/header.scss";
@import "./home-two/home-scrubbers-types.scss";
@import "./home-two/home-two-gallery.scss";
@import "./home-two/home-two.scss";
@import "./home-two/popup.scss";
@import "./rental.scss";
@import "./loader.scss";
@import "./machine-specific-gallery.scss";
* {
	margin: 0rem;
	padding: 0rem;
	box-sizing: border-box;
	font-family: Arial, Helvetica, sans-serif;
	// hide scroll bar
}
html {
	font-size: 16px;
}
.red-asterisk {
	color: red;
	font-weight: bold;
}

body {
	display: grid;
	grid-template-rows: 1fr auto;
	min-height: 100vh;
	align-content: flex-start;
	max-width: calc(2800px - 17px);
	min-height: 100vh;
	margin: auto;
	font-size: 1rem;
}
button {
	cursor: pointer;
}
p {
	padding: 0.5rem 0rem;
	font-size: 0.85rem;
}
.nopad-page {
	display: grid;
	&.dark {
		background: rgba($color: #000000, $alpha: 0.75);
		color: white;
	}
}
.no-header-page {
	display: grid;
	padding: $mobile-width-padding;
	padding-top: 4.5rem;
	padding-bottom: 1rem;
	color: black;
	h2 {
		margin-bottom: 2rem;
		&.title {
			border-left: 8px solid $factorycatred;
			font-size: 1.5rem;
			padding-left: 2rem;
			text-transform: uppercase;
			margin-bottom: 1rem;
		}
	}
	h1 {
		border-left: 8px solid $factorycatred;
		font-size: 1.5rem;
		padding-left: 2rem;
		text-transform: uppercase;
		margin-bottom: 1rem;
		height: min-content;
	}
	ul {
		list-style-type: none;
		li {
			a {
				color: $factorycatred;
				font-weight: bold;
			}
		}
	}
}
.standard-page {
	display: grid;
	padding: $mobile-width-padding;
	padding-top: 4rem;
	padding-bottom: 1rem;
	align-content: flex-start;

	h2 {
		margin-bottom: 2rem;
		&.title {
			border-left: 8px solid $factorycatred;
			font-size: 1.5rem;
			padding-left: 2rem;
			text-transform: uppercase;
			margin-bottom: 1rem;
		}
	}
	h1 {
		border-left: 8px solid $factorycatred;
		font-size: 1.5rem;
		padding-left: 2rem;
		text-transform: uppercase;
		margin-bottom: 1rem;
	}
	&.contact-us {
		padding: 3rem 0rem 0rem 0rem;
	}
	&.support-us {
		padding: 0rem 0rem;
	}
	&.errored {
		max-width: 1200px;
		margin: auto;
		h1,
		h2 {
			border: none;
			padding: 0rem;
			text-align: center;
		}
	}
	ul {
		list-style-type: none;
		li {
			a {
				color: $factorycatred;
				font-weight: bold;
			}
		}
	}
}

@include responsive(small) {
}
@include responsive(medium) {
	.standard-page {
		padding: $notepad-width-padding;
		padding-top: 4rem;
		padding-bottom: 2rem;
		&.support {
			padding-top: 5rem;
		}
		h1 {
			font-size: 2rem;
		}
	}
}
@include responsive(large) {
	p {
		padding: 0.5rem 0rem;
		font-size: 1rem;
	}
	.standard-page {
		padding: $desktop-width-padding;
		padding-top: 4rem;
		padding-bottom: 2rem;
		&.support {
			padding-top: 5rem;
		}
	}
}
